import { clientAreaTrx, loginTrx, signUpTrx } from './router-translation.labels';
import { LoginStandaloneComponent } from './components/auth/login-dialog/login-dialog.component';

export const loginRoute = {
  path: loginTrx,
  component: LoginStandaloneComponent
};

export const signupRoute = {
  path: signUpTrx,
  loadChildren: () => import('./modules/signup/signup-standalone.module').then(m => m.SignupStandaloneModule)
};

export const clientAreaRoute = {
  path: clientAreaTrx,
  canActivate: [],  /* AuthGuard */
  loadChildren: () => import('./components/client-area/client-area.module').then(m => m.ClientAreaModule),
  runGuardsAndResolvers: 'always'
};
